import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('typePage', "");

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Copy generator 
export function Reg_CopyGenerator(userId, id, idioma, copyIdioma, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', "portfolio");
    data.append('edit_data', "copy_generator");

    data.append("id", id);
    data.append('userId', userId);
    data.append('idioma', idioma);
    data.append('copyIdioma', copyIdioma);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        SetListDataSingle('portfolio', response.data['portfolio']);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_data', 'alt_position');
    data.append('type_page', current_page);
    data.append('typePage', "");

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data, current_page);
        SetListDataSingle(current_page, response.data[current_page]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit Config
export function Reg_EditConfig(idiomaEn, idiomaEs, idiomaPtBr, listName, linkWhatsapp, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'dataConfig');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idiomaEn', idiomaEn);
    data.append('idiomaEs', idiomaEs);
    data.append('idiomaPtBr', idiomaPtBr);
    data.append('link_whatsapp', linkWhatsapp);

    listName.forEach((elem, index) => {
        data.append('config_id[]', elem.id);
        data.append('config_en[]', elem.en);
        data.append('config_es[]', elem.es);
        data.append('config_pt_br[]', elem.pt_br);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('dataConfig', response.data.dataConfig);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('accessDash', response.data.accessDash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.accessDash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('accessDash', response.data.accessDash);
        const newData = response.data.accessDash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register data
export function Reg_RegisterData(current_page, userId, idPage, typePage, enMaskTop, enMaskBottom, enColorPage, esMaskTop, esMaskBottom, esColorPage, ptBrMaskTop, ptBrMaskBottom, ptBrColorPage, dataRegister, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', current_page);

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('typePage', typePage);
    data.append('enMaskTop', enMaskTop);
    data.append('enMaskBottom', enMaskBottom);
    data.append('enColorPage', enColorPage);
    data.append('esMaskTop', esMaskTop);
    data.append('esMaskBottom', esMaskBottom);
    data.append('esColorPage', esColorPage);
    data.append('ptBrMaskTop', ptBrMaskTop);
    data.append('ptBrMaskBottom', ptBrMaskBottom);
    data.append('ptBrColorPage', ptBrColorPage);

    switch (typePage) {
        case "home":
                data.append('pt_br_title', dataRegister.pt_br_title);   
                data.append('pt_br_type_video', dataRegister.pt_br_type_video);  
                data.append('pt_br_file', dataRegister.pt_br_file);  
                data.append('pt_br_text', dataRegister.pt_br_text);   
                
                data.append('en_title', dataRegister.en_title);   
                data.append('en_type_video', dataRegister.en_type_video);  
                data.append('en_file', dataRegister.en_file);  
                data.append('en_text', dataRegister.en_text);   
                
                data.append('es_title', dataRegister.es_title);   
                data.append('es_type_video', dataRegister.es_type_video);  
                data.append('es_file', dataRegister.es_file);  
                data.append('es_text', dataRegister.es_text);
            break;

        case "text": case "contact":
                data.append('pt_br_subtitle', dataRegister.pt_br_subtitle);  
                data.append('pt_br_title', dataRegister.pt_br_title);   
                data.append('pt_br_text', dataRegister.pt_br_text);   
                
                data.append('en_subtitle', dataRegister.en_subtitle); 
                data.append('en_title', dataRegister.en_title); 
                data.append('en_text', dataRegister.en_text);  
                
                data.append('es_subtitle', dataRegister.es_subtitle);
                data.append('es_title', dataRegister.es_title);
                data.append('es_text', dataRegister.es_text);
            break;

        case "video":
                data.append('pt_br_title', dataRegister.pt_br_title);   
                data.append('pt_br_subtitle', dataRegister.pt_br_subtitle);  
                data.append('pt_br_type_video', dataRegister.pt_br_type_video);  
                data.append('pt_br_file', dataRegister.pt_br_file);   
                
                data.append('en_title', dataRegister.en_title);   
                data.append('en_subtitle', dataRegister.en_subtitle); 
                data.append('en_type_video', dataRegister.en_type_video);  
                data.append('en_file', dataRegister.en_file);  
                
                data.append('es_title', dataRegister.es_title); 
                data.append('es_subtitle', dataRegister.es_subtitle);  
                data.append('es_type_video', dataRegister.es_type_video);  
                data.append('es_file', dataRegister.es_file); 
            break;

        case "clients":
                data.append('pt_br_subtitle', dataRegister.pt_br_subtitle);  
                data.append('pt_br_title', dataRegister.pt_br_title);
                data.append('pt_br_text', dataRegister.pt_br_text);
                if(dataRegister.pt_br_contents){
                    dataRegister.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_contents_id[]', elem.id);
                        data.append('pt_br_contents_language[]', elem.language);
                        data.append('pt_br_contents_title[]', elem.title);
                        data.append('pt_br_contents_file_' + index, elem.file);
                    });
                }

                data.append('en_subtitle', dataRegister.en_subtitle); 
                data.append('en_title', dataRegister.en_title); 
                data.append('en_text', dataRegister.en_text);
                if(dataRegister.en_contents){
                    dataRegister.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_language[]', elem.language);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_file_' + index, elem.file);
                    });
                }
                
                data.append('es_subtitle', dataRegister.es_subtitle);
                data.append('es_title', dataRegister.es_title);
                data.append('es_text', dataRegister.es_text);
                if(dataRegister.es_contents){
                    dataRegister.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_language[]', elem.language);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_file_' + index, elem.file);
                    });
                }
            break;

        case "spotlight":
                if(dataRegister.pt_br_contents){
                    dataRegister.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_contents_id[]', elem.id);
                        data.append('pt_br_contents_title[]', elem.title);
                        data.append('pt_br_contents_subtitle[]', elem.subtitle);
                        data.append('pt_br_contents_portfolio[]', elem.id_portfolio);
                        data.append('pt_br_contents_file_' + index, elem.file);
                    });
                }

                if(dataRegister.en_contents){
                    dataRegister.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_subtitle[]', elem.subtitle);
                        data.append('en_contents_portfolio[]', elem.id_portfolio);
                        data.append('en_contents_file_' + index, elem.file);
                    });
                }

                if(dataRegister.es_contents){
                    dataRegister.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_subtitle[]', elem.subtitle);
                        data.append('es_contents_portfolio[]', elem.id_portfolio);
                        data.append('es_contents_file_' + index, elem.file);
                    });
                }
            break;

        case "portfolio":
                data.append('pt_br_portfolios', dataRegister.pt_br_portfolios); 
                if(dataRegister.pt_br_contents){
                    dataRegister.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_contents_id[]', elem.id);
                        data.append('pt_br_contents_title[]', elem.title);
                        data.append('pt_br_contents_text[]', elem.text);
                        data.append('pt_br_contents_portfolio[]', elem.id_portfolio);
                        data.append('pt_br_contents_file_' + index, elem.file);
                    });
                }

                data.append('en_portfolios', dataRegister.en_portfolios); 
                if(dataRegister.en_contents){
                    dataRegister.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_text[]', elem.text);
                        data.append('en_contents_portfolio[]', elem.id_portfolio);
                        data.append('en_contents_file_' + index, elem.file);
                    });
                }

                data.append('es_portfolios', dataRegister.es_portfolios); 
                if(dataRegister.es_contents){
                    dataRegister.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_text[]', elem.text);
                        data.append('es_contents_portfolio[]', elem.id_portfolio);
                        data.append('es_contents_file_' + index, elem.file);
                    });
                }
            break;

        case "our_services":
                data.append('pt_br_subtitle', dataRegister.pt_br_subtitle);  
                data.append('pt_br_title', dataRegister.pt_br_title);
                data.append('pt_br_text', dataRegister.pt_br_text);
                if(dataRegister.pt_br_contents){
                    dataRegister.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_contents_id[]', elem.id);
                        data.append('pt_br_contents_language[]', elem.language);
                        data.append('pt_br_contents_title[]', elem.title);
                        data.append('pt_br_contents_text[]', elem.text);
                    });
                }

                data.append('en_subtitle', dataRegister.en_subtitle); 
                data.append('en_title', dataRegister.en_title); 
                data.append('en_text', dataRegister.en_text);
                if(dataRegister.en_contents){
                    dataRegister.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_language[]', elem.language);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_text[]', elem.text);
                    });
                }
                
                data.append('es_subtitle', dataRegister.es_subtitle);
                data.append('es_title', dataRegister.es_title);
                data.append('es_text', dataRegister.es_text);
                if(dataRegister.es_contents){
                    dataRegister.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_language[]', elem.language);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_text[]', elem.text);
                    });
                }
            break;

        case "show_numbers":
                data.append('pt_br_subtitle', dataRegister.pt_br_subtitle);  
                data.append('pt_br_title', dataRegister.pt_br_title);
                if(dataRegister.pt_br_contents){
                    dataRegister.pt_br_contents.forEach((elem, index)=> {
                        data.append('pt_br_contents_id[]', elem.id);
                        data.append('pt_br_contents_language[]', elem.language);
                        data.append('pt_br_contents_title[]', elem.title);
                        data.append('pt_br_contents_subtitle[]', elem.subtitle);
                        data.append('pt_br_contents_text[]', elem.text);
                    });
                }

                data.append('en_subtitle', dataRegister.en_subtitle); 
                data.append('en_title', dataRegister.en_title); 
                if(dataRegister.en_contents){
                    dataRegister.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_language[]', elem.language);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_subtitle[]', elem.subtitle);
                        data.append('en_contents_text[]', elem.text);
                    });
                }
                
                data.append('es_subtitle', dataRegister.es_subtitle);
                data.append('es_title', dataRegister.es_title);
                if(dataRegister.es_contents){
                    dataRegister.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_language[]', elem.language);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_subtitle[]', elem.subtitle);
                        data.append('es_contents_text[]', elem.text);
                    });
                }
            break;
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle(current_page, response.data[current_page]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Register portfolio
export function Reg_RegisterPortfolio(userId, idPage, client, color, title, year, thumbnail, ptBrId, ptBrProject, ptBrSubtitle, ptBrType, ptBrText, ptBrContents, ptBrDatasheet, ptBrFile, ptBrPositionX, ptBrPositionY, ptBrWidth, ptBrHeight, ptBrTypeCrop, ptBrShowWidth, ptBrWidthReal, enId, enProject, enSubtitle, enType, enText, enContents, enDatasheet, enFile, enPositionX, enPositionY, enWidth, enHeight, enTypeCrop, enShowWidth, enWidthReal, esId, esProject, esSubtitle, esType, esText, esContents, esDatasheet, esFile, esPositionX, esPositionY, esWidth, esHeight, esTypeCrop, esShowWidth, esWidthReal, CallbackSuccess, CallbackError){

    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', 'portfolio');
    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('client', client);
    data.append('color', color);
    data.append('title', title);
    data.append('year', year);
    data.append('thumbnail', thumbnail);

    // pt_br
    data.append('pt_br_id', ptBrId);
    data.append('pt_br_project', ptBrProject);
    data.append('pt_br_subtitle', ptBrSubtitle);
    data.append('pt_br_type', ptBrType);
    data.append('pt_br_text', ptBrText);
    data.append('pt_br_datasheet', ptBrDatasheet);
    data.append('pt_br_file', ptBrFile);

    data.append('pt_br_position_x', ptBrPositionX);
    data.append('pt_br_position_y', ptBrPositionY);
    data.append('pt_br_width', ptBrWidth);
    data.append('pt_br_height', ptBrHeight);
    data.append('pt_br_type_crop', ptBrTypeCrop);
    data.append('pt_br_show_width', ptBrShowWidth);
    data.append('pt_br_width_real', ptBrWidthReal);

    if(ptBrContents){
        ptBrContents.forEach((elent_0, index_0) => {
            data.append('pt_br_data_id[]', elent_0.id);
            data.append('pt_br_data_type[]', elent_0.type);
            data.append('pt_br_data_container[]', elent_0.container);
            data.append('pt_br_data_width[]', elent_0.width);
            data.append('pt_br_data_height[]', elent_0.height);

            if(elent_0.data_contents){
                elent_0.data_contents.forEach((elent_1, index_1) => {
                    data.append('pt_br_index_0[]', index_0);
                    data.append('pt_br_index_1[]', index_1);
                    data.append('pt_br_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('pt_br_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('pt_br_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('pt_br_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('pt_br_contents_height_' + index_0 + '_' + index_1, elent_1.height);                  

                    data.append('pt_br_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('pt_br_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('pt_br_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('pt_br_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('pt_br_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('pt_br_contents_text_' + index_0 + '_' + index_1, elent_1.text);                    

                    data.append('pt_br_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('pt_br_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('pt_br_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('pt_br_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('pt_br_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('pt_br_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('pt_br_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('pt_br_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('pt_br_gallery_index_0[]', index_0);
                            data.append('pt_br_gallery_index_1[]', index_1);
                            data.append('pt_br_gallery_index_2[]', index_2);

                            data.append('pt_br_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('pt_br_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('pt_br_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('pt_br_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    // en
    data.append('en_id', enId);
    data.append('en_project', enProject);
    data.append('en_subtitle', enSubtitle);
    data.append('en_type', enType);
    data.append('en_text', enText);
    data.append('en_datasheet', enDatasheet);
    data.append('en_file', enFile);

    data.append('en_position_x', enPositionX);
    data.append('en_position_y', enPositionY);
    data.append('en_width', enWidth);
    data.append('en_height', enHeight);
    data.append('en_type_crop', enTypeCrop);
    data.append('en_show_width', enShowWidth);
    data.append('en_width_real', enWidthReal);
    
    if(enContents){
        enContents.forEach((elent_0, index_0) => {
            data.append('en_data_id[]', elent_0.id);
            data.append('en_data_type[]', elent_0.type);
            data.append('en_data_container[]', elent_0.container);
            data.append('en_data_width[]', elent_0.width);
            data.append('en_data_height[]', elent_0.height);

            if(elent_0.data_contents){
                elent_0.data_contents.forEach((elent_1, index_1) => {
                    data.append('en_index_0[]', index_0);
                    data.append('en_index_1[]', index_1);
                    data.append('en_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('en_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('en_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('en_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('en_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('en_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('en_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('en_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('en_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('en_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    

                    data.append('en_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('en_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('en_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('en_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('en_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('en_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('en_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('en_gallery_index_0[]', index_0);
                            data.append('en_gallery_index_1[]', index_1);
                            data.append('en_gallery_index_2[]', index_2);

                            data.append('en_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('en_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('en_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('en_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    // es
    data.append('es_id', esId);
    data.append('es_project', esProject);
    data.append('es_subtitle', esSubtitle);
    data.append('es_type', esType);
    data.append('es_text', esText);
    data.append('es_datasheet', esDatasheet);
    data.append('es_file', esFile);

    data.append('es_position_x', esPositionX);
    data.append('es_position_y', esPositionY);
    data.append('es_width', esWidth);
    data.append('es_height', esHeight);
    data.append('es_type_crop', esTypeCrop);
    data.append('es_show_width', esShowWidth);
    data.append('es_width_real', esWidthReal);

    if(esContents){
        esContents.forEach((elent_0, index_0) => {
            data.append('es_data_id[]', elent_0.id);
            data.append('es_data_type[]', elent_0.type);
            data.append('es_data_container[]', elent_0.container);
            data.append('es_data_width[]', elent_0.width);
            data.append('es_data_height[]', elent_0.height);

            if(elent_0.data_contents){
                elent_0.data_contents.forEach((elent_1, index_1) => {
                    data.append('es_index_0[]', index_0);
                    data.append('es_index_1[]', index_1);
                    data.append('es_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('es_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('es_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('es_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('es_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('es_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('es_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('es_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('es_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('es_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    

                    data.append('es_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('es_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('es_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('es_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('es_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('es_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('es_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('es_gallery_index_0[]', index_0);
                            data.append('es_gallery_index_1[]', index_1);
                            data.append('es_gallery_index_2[]', index_2);

                            data.append('es_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('es_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('es_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('es_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        console.log(response.data);
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('portfolio', response.data.portfolio);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
