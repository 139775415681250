import { useState, useEffect } from "react";

import './Portfolio.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function ModelPage_Portfolio(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ listPortfolio, setListPortfolio ] = useState(GetDataPage('portfolio'));

    // pt_br
    const [ ptBrPortfolios, setPtBrPortfolios ] = useState(InitialData('pt_br_type'));
    const [ ptBrContents, setPtBrContents]     = useState(InitialData('pt_br_contents'));
    // en
    const [ enPortfolios, setEnPortfolios ] = useState(InitialData('en_type'));
    const [ enContents, setEnContents]      = useState(InitialData('en_contents'));
    // es
    const [ esPortfolios, setEsPortfolios ] = useState(InitialData('es_type'));
    const [ esContents, setEsContents]      = useState(InitialData('es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('portfolio', setListPortfolio);

        LoadingData();
    }, []);

    useEffect(()=>{
        LoadingData();
        // pt_br
        setPtBrContents(InitialData('pt_br_contents'));
        // en
        setEnContents(InitialData('en_contents'));
        // es
        setEsContents(InitialData('es_contents'));
    }, [dataPage, idPage]);

    function LoadingData(){
        props.RegisterData({ "pt_br_portfolios": ptBrPortfolios, "pt_br_contents": ptBrContents, "en_portfolios": enPortfolios, "en_contents": enContents, "es_portfolios": esPortfolios, "es_contents": esContents });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            // data - pt_br
            if(type == 'pt_br_contents'){
                const contents = newData.contents.filter(item => item.language == 'pt_br');
                return contents;
            }
            // data - en
            if(type == 'en_contents'){
                const contents = newData.contents.filter(item => item.language == 'en');
                return contents;
            }
            // data - es
            if(type == 'es_contents'){
                const contents = newData.contents.filter(item => item.language == 'es');
                return contents;
            }
            return newData[type];
        }
        if(type == 'pt_br_contents' || type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        if(type == 'pt_br_type' || type == 'en_type' || type == 'es_type'){
            return false;
        }
        return "";
    }

    function ListPortfolio(){
        const options = [];
        listPortfolio.map((key, index)=>{
            let labelOpt = key.client + ' - ' + key.title;
            options.push({
                "value": key.id, "label": labelOpt
            });
        })
        return options;
    }

    function NewContent(){
        if(idioma == 'pt_br'){
            setPtBrContents([...ptBrContents, { "id": 0, "title": "", "text": "", "id_portfolio": 0, "client": "Selecione um portfolio", "file": "", "status": false }]);      
        }
        
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "title": "", "text": "", "id_portfolio": 0, "client": "Select a portfolio", "file": "", "status": false }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "title": "", "text": "", "id_portfolio": 0, "client": "Seleccione una cartera", "file": "", "status": false }]);              
        }
    }

    function HandleData(type, index, value){
        if(idioma == "pt_br"){
            const newData = [...ptBrContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else if(type == 'portfolio'){
                newData[index]['id_portfolio'] = value.value;
                newData[index]['client']       = value.label;
            }else {
                newData[index][type] = value;
            }
            props.RegisterData({ "pt_br_portfolios": ptBrPortfolios, "pt_br_contents": newData, "en_portfolios": enPortfolios, "en_contents": enContents, "es_portfolios": esPortfolios, "es_contents": esContents });
            setPtBrContents(newData);
        }

        if(idioma == "en"){
            const newData = [...enContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else if(type == 'portfolio'){
                newData[index]['id_portfolio'] = value.value;
                newData[index]['client']       = value.label;
            }else {
                newData[index][type] = value;
            }
            props.RegisterData({ "pt_br_portfolios": ptBrPortfolios, "pt_br_contents": ptBrContents, "en_portfolios": enPortfolios, "en_contents": newData, "es_portfolios": esPortfolios, "es_contents": esContents });
            setEnContents(newData);
        }

        if(idioma == "es"){
            const newData = [...esContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else if(type == 'portfolio'){
                newData[index]['id_portfolio'] = value.value;
                newData[index]['client']       = value.label;
            }else {
                newData[index][type] = value;
            }
            props.RegisterData({ "pt_br_portfolios": ptBrPortfolios, "pt_br_contents": ptBrContents, "en_portfolios": enPortfolios, "en_contents": enContents, "es_portfolios": esPortfolios, "es_contents": newData });
            setEsContents(newData);
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){
            if(idioma == 'pt_br'){
                const newData = [...ptBrContents];
                newData.splice(index, 1);
                setPtBrContents(newData);        
            }
            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": props.origin, "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelPage_Portfolio">
            {
                idioma == 'pt_br' ? 
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="div_subtitle_add">
                                <div className="">Portfólios</div>
                                <div className="add_new_data">
                                    <div className="">Adicionar todos portfólios</div>
                                    <div className="div_restricted_switch">
                                        <label className="switch">
                                            <div className="switch_wrapper">
                                                <input type="checkbox" onChange={ (e)=>{ setPtBrPortfolios(!ptBrPortfolios) } } checked={ ptBrPortfolios } />
                                                <span className="switch_button" />
                                            </div>
                                        </label>
                                    </div>
                                    {
                                        ptBrPortfolios == false ? 
                                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                            <span className="icons_menu">+</span> escolher portfólios
                                        </div> : null
                                    } 
                                </div>                                 
                            </div>
                            {
                                ptBrPortfolios == true ? null : 
                                ptBrContents.map((key, index)=>{
                                    return(      
                                        <div className="list_contents" key={ index }>
                                            <div className="list_data_input">     
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ key.title } required />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <Select className="select_signature" defaultOptions options={ ListPortfolio() } onChange={ (e)=> { HandleData('portfolio', index, e) } } value={{ "value": key.id_portfolio, "label": key.client }} placeholder="..." required />
                                                    <span className="name_input">Portfólios</span>
                                                </div>
                                                <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            <div className="div_input space_div">
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('text', index, newContent) } />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    idioma == 'en' ?
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="div_subtitle_add">
                                <div className="">Portfolios</div>
                                <div className="add_new_data">
                                    <div className="">Add all portfolios</div>
                                    <div className="div_restricted_switch">
                                        <label className="switch">
                                            <div className="switch_wrapper">
                                                <input type="checkbox" onChange={ (e)=>{ setEnPortfolios(!enPortfolios) } } checked={ enPortfolios } />
                                                <span className="switch_button" />
                                            </div>
                                        </label>
                                    </div>
                                    {
                                        enPortfolios == false ? 
                                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                            <span className="icons_menu">+</span> choose portfolios
                                        </div> : null
                                    } 
                                </div>                                 
                            </div>
                            {
                                enPortfolios == true ? null :
                                enContents.map((key, index)=>{
                                    return(      
                                        <div className="list_contents" key={ index }>
                                            <div className="list_data_input">                                                
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ key.title } required />
                                                    <span className="name_input">Title</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <Select className="select_signature" defaultOptions options={ ListPortfolio() } onChange={ (e)=> { HandleData('portfolio', index, e) } } value={{ "value": key.id_portfolio, "label": key.client }} placeholder="..." required />
                                                    <span className="name_input">Portfolios</span>
                                                </div>
                                                <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            <div className="div_input space_div">
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('text', index, newContent) } />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="div_subtitle_add">
                                <div className="">Portafolios</div>
                                <div className="add_new_data">
                                    <div className="">Agregar todas las carteras</div>
                                    <div className="div_restricted_switch">
                                        <label className="switch">
                                            <div className="switch_wrapper">
                                                <input type="checkbox" onChange={ (e)=>{ setEsPortfolios(!esPortfolios) } } checked={ esPortfolios } />
                                                <span className="switch_button" />
                                            </div>
                                        </label>
                                    </div>
                                    {
                                        esPortfolios == false ? 
                                        <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                            <span className="icons_menu">+</span> elegir carteras
                                        </div> : null
                                    } 
                                </div>                                 
                            </div>
                            {
                                esPortfolios == true ? null :
                                esContents.map((key, index)=>{
                                    return(      
                                        <div className="list_contents" key={ index }>
                                            <div className="list_data_input">                                                
                                                <div className="div_input space_div">
                                                    <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ key.title } required />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <Select className="select_signature" defaultOptions options={ ListPortfolio() } onChange={ (e)=> { HandleData('portfolio', index, e) } } value={{ "value": key.id_portfolio, "label": key.client }} placeholder="..." required />
                                                    <span className="name_input">Portafolios</span>
                                                </div>
                                                <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            <div className="div_input space_div">
                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('text', index, newContent) } />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    )
}