import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { showPageList } from "fixedData";

import { SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_RegisterData } from "services/RegisterData";

import ModelPage_Home from "components/ModelPage/Home";

import ModelPage_Text from "components/ModelPage/Text";
import ModelPage_Video from "components/ModelPage/Video";
import ModelPage_Clients from "components/ModelPage/Clients";
import ModelPage_Spotlight from "components/ModelPage/Spotlight";
import ModelPage_Portfolio from "components/ModelPage/Portfolio";
import ModelPage_OurServices from "components/ModelPage/OurServices";
import ModelPage_ShowNumbers from "components/ModelPage/ShowNumbers";

export default function Page_Works_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("works"));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ typePage, setTypePage ]   = useState(InitialData('type_page'));

    const [ enColorPage, setEnColorPage ]   = useState(InitialData('en_color'));
    const [ enMaskTop, setEnMaskTop ]       = useState(InitialData('en_mask_top'));
    const [ enMaskBottom, setEnMaskBottom ] = useState(InitialData('en_mask_bottom'));

    const [ esColorPage, setEsColorPage ]   = useState(InitialData('es_color'));
    const [ esMaskTop, setEsMaskTop ]       = useState(InitialData('es_mask_top'));
    const [ esMaskBottom, setEsMaskBottom ] = useState(InitialData('es_mask_bottom'));

    const [ ptBrColorPage, setPtBrColorPage ]   = useState(InitialData('pt_br_color'));
    const [ ptBrMaskTop, setPtBrMaskTop ]       = useState(InitialData('pt_br_mask_top'));
    const [ ptBrMaskBottom, setPtBrMaskBottom ] = useState(InitialData('pt_br_mask_bottom'));

    const [ dataRegister, setDataRegister ] = useState(InitialData('all'));

    const [ namePageIdioma, setNamePageIdioma ] = useState([
        {
            "en": "Details",
            "es": "Detalles",
            "pt_br": "Detalhes"
        },
        {
            "en": "To save",
            "es": "Ahorrar",
            "pt_br": "Salvar"
        },
        {
            "en": "Page data",
            "es": "Datos de la página",
            "pt_br": "Dados da página"
        },
        {
            "en": "Page type",
            "es": "Tipo de página",
            "pt_br": "Tipo de página"
        },
        {
            "en": "Background color",
            "es": "Color de fondo",
            "pt_br": "Cor de fundo"
        },
        {
            "en": "Example of how the page will look",
            "es": "Ejemplo de cómo se verá la página",
            "pt_br": "Exemplo de como ficará a página"
        }
    ]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('works', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("works"));
        setIdPage(GetListPag('currentPageId'));

        setTypePage(InitialData('type_page'));

        setEnColorPage(InitialData('en_color'));
        setEnMaskTop(InitialData('en_mask_top'));
        setEnMaskBottom(InitialData('en_mask_bottom'));

        setEsColorPage(InitialData('es_color'));
        setEsMaskTop(InitialData('es_mask_top'));
        setEsMaskBottom(InitialData('es_mask_bottom'));

        setPtBrColorPage(InitialData('pt_br_color'));
        setPtBrMaskTop(InitialData('pt_br_mask_top'));
        setPtBrMaskBottom(InitialData('pt_br_mask_bottom'));

        setDataRegister(InitialData('all'));
    }, [idPage, dataPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'all'){
                return newData;
            }else {
                if(type == 'pt_br_mask_top' || type == 'pt_br_mask_bottom' || type == 'en_mask_top' || type == 'en_mask_bottom' || type == 'es_mask_top' || type == 'es_mask_bottom'){
                    if(newData[type] == 0){
                        return { "value": 0, "label": "Não" };
                    }else {
                        return { "value": 1, "label": "Sim" };
                    }
                }
                return newData[type];
            }
        }
        if(type == 'en_color' || type == 'es_color' || type == 'pt_br_color'){
            return '#121212';
        }
        if(type == 'pt_br_mask_top' || type == 'pt_br_mask_bottom' || type == 'en_mask_top' || type == 'en_mask_bottom' || type == 'es_mask_top' || type == 'es_mask_bottom'){
            return { "value": 0, "label": "Não" };
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'works');
        SetListPag('currentPageId', 'remuve');
    }

    function ClickIdioma(value){
        setIdioma(value);
        SetListPag('idioma', value);
    }

    function RegisterData(value){
        setDataRegister(value);
    }

    function CurrentPage(){
        switch (typePage) {
            case "home":
                return <ModelPage_Home origin="works" RegisterData={ RegisterData } />;

            case "text": case "contact":
                return <ModelPage_Text origin="works" RegisterData={ RegisterData } />;

            case "video":
                return <ModelPage_Video origin="works" RegisterData={ RegisterData } />;
                
            case "clients":
                return <ModelPage_Clients origin="works" RegisterData={ RegisterData } />;

            case "our_services":
                return <ModelPage_OurServices origin="works" RegisterData={ RegisterData } />;
                
            case "spotlight":
                return <ModelPage_Spotlight origin="works" RegisterData={ RegisterData } />;

            case "portfolio":
                return <ModelPage_Portfolio origin="works" RegisterData={ RegisterData } />;

            case "show_numbers":
                return <ModelPage_ShowNumbers origin="works" RegisterData={ RegisterData } />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_RegisterData('works', userId, idPage, typePage, enMaskTop.value, enMaskBottom.value, enColorPage, esMaskTop.value, esMaskBottom.value, esColorPage, ptBrMaskTop.value, ptBrMaskBottom.value, ptBrColorPage, dataRegister, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Page_Works_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Como funciona
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            { namePageIdioma[0][idioma] }
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className={ idioma == 'pt_br' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('pt_br'); } }>pt-br</div>
                        <div className={ idioma == 'en' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('en') } }>en</div>
                        <div className={ idioma == 'es' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('es') } }>es</div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                { namePageIdioma[1][idioma] }
                            </div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">{ namePageIdioma[2][idioma] }</div>
                    </div>
                    <div className="list_input_data">
                        {
                            idPage == 0 ?
                            <div className="div_input">
                                <select onChange={ (e)=>{ setTypePage(e.target.value) } } value={ typePage } required>
                                    <option value="">#</option>
                                    {
                                        showPageList.map((key, index)=>{
                                            return(
                                                <option value={ key.key } key={ index }>{ key.name[idioma] }</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="name_input">{ namePageIdioma[3][idioma] }</span>
                            </div> : null
                        }
                        {
                            idioma == 'pt_br' ? 
                            <>
                                <div className="div_input">
                                    <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setPtBrMaskTop(e) } } value={ ptBrMaskTop } placeholder="..." />
                                    <span className="name_input">Mascara top</span>
                                </div>
                                <div className="div_input">
                                    <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setPtBrMaskBottom(e) } } value={ ptBrMaskBottom } placeholder="..." />
                                    <span className="name_input">Mascara bottom</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setPtBrColorPage(e.target.value) } } value={ ptBrColorPage } required />
                                    <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                                </div>
                                <div className="div_input">
                                    <div className="show_color" style={ { backgroundColor: ptBrColorPage } } />
                                </div>
                            </> : 
                                idioma == 'en' ? 
                                <>
                                    <div className="div_input">
                                        <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setEnMaskTop(e) } } value={ enMaskTop } placeholder="..." />
                                        <span className="name_input">Mask top</span>
                                    </div>
                                    <div className="div_input">
                                        <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setEnMaskBottom(e) } } value={ enMaskBottom } placeholder="..." />
                                        <span className="name_input">Mask bottom</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setEnColorPage(e.target.value) } } value={ enColorPage } required />
                                        <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                                    </div>
                                    <div className="div_input">
                                        <div className="show_color" style={ { backgroundColor: enColorPage } } />
                                    </div>
                                </> : 
                                <>
                                    <div className="div_input">
                                        <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setEsMaskTop(e) } } value={ esMaskTop } placeholder="..." />
                                        <span className="name_input">Máscara superior</span>
                                    </div>
                                    <div className="div_input">
                                        <Select className="select_mask" defaultOptions options={[{ "value": "0", "label": "Não" }, { "value": "1", "label": "Sim" }]} onChange={ (e)=> { setEsMaskBottom(e) } } value={ esMaskBottom } placeholder="..." />
                                        <span className="name_input">Máscara inferior</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setEsColorPage(e.target.value) } } value={ esColorPage } required />
                                        <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                                    </div>
                                    <div className="div_input">
                                        <div className="show_color" style={ { backgroundColor: esColorPage } } />
                                    </div>
                                </>
                        }
                    </div>
                    { CurrentPage() }
                </div>

                {
                    typePage == '' ? null : 
                    <div className="show_page_data"> 
                        <div className="list_type_data">
                            <div className="type_title">{ namePageIdioma[5][idioma] }</div>
                        </div>
                        <div className="list_input_data">
                        </div>
                    </div> 
                }
            </form>
        </div>
    )
}