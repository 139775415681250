import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_CopyGenerator, Reg_Delete } from "services/RegisterData";

import { GetUserData, RegisterUserData } from "interface/Users";
import { SvgClose } from "components/SvgFile";

export default function PopUP_Confirmation(){
    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    const [ type, setType ]             = useState(0);
    const [ idioma, setIdioma ]         = useState('');
    const [ copyIdioma, setCopyIdioma ] = useState('');

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            modalData.type == 'duplicate' ? setType(1) : setType(0);
            modalData.type == 'duplicate' ? setIdioma(modalData.idioma) : setIdioma('pt_br');
        }
    }, [showPopUp]);

    function TypeAlt(){
        switch (modalData.type) {
            case "update_json": case "duplicate":
                break;

            default:
                    Reg_Delete(userId, modalData.id, modalData.origin, modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
        }
    }

    function DuplicateData(){
        setLoading(true);
        Reg_CopyGenerator(userId, modalData.id, idioma, copyIdioma, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('Confirmation', false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalState('Confirmation', false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "update_json":
                return "Atualizar site";
            case "duplicate":
                return "Duplicar dados";
            case "disabled_access_dash":
                return (
                    <div>
                        Desativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "reactivate_access_dash":
                return (
                    <div>
                        Reativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all Confirmation">
                        {
                            type == 0 ?
                            <div className="div_data">
                                <div className="title">
                                    { TypeRemoval() }
                                </div>
                            </div> :
                            <div className="div_data type_div">
                                <div className="title">
                                    DUPLICAR DADOS
                                </div>
                                <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                        }                       

                        <div className="div_data" style={ { paddingTop: 0 } }>                            
                            {
                                type == 0 ?
                                <div className="content div_confirmation">
                                    <div onClick={ ()=>{ TypeAlt(); } }>
                                        <div className="opt_select yes_update">Sim</div>
                                    </div>
                                    <div onClick={ ()=>{ ClosePopUp(); } }>
                                        <div className="opt_select not_update">Não</div>
                                    </div>
                                </div> :
                                <div className="content">
                                    <div className="text_inf">Escolha de qual idioma deseja copiar</div>
                                    <div className="content div_confirmation">
                                        {
                                            idioma == 'pt_br' ? null : 
                                            <div className="" onClick={ ()=>{ setCopyIdioma('pt_br') } }>
                                                <div className={ copyIdioma == 'pt_br' ? "opt_select select_idioma yes_update" : "opt_select select_idioma" }>Português</div>
                                            </div>
                                        }
                                        {
                                            idioma == 'en' ? null : 
                                            <div className="" onClick={ ()=>{ setCopyIdioma('en') } }>
                                                <div className={ copyIdioma == 'en' ? "opt_select select_idioma yes_update" : "opt_select select_idioma" }>Inglês</div>
                                            </div>
                                        }
                                        {
                                            idioma == 'es' ? null : 
                                            <div className="" onClick={ ()=>{ setCopyIdioma('es') } }>
                                                <div className={ copyIdioma == 'es' ? "opt_select select_idioma yes_update" : "opt_select select_idioma" }>Espanhol</div>
                                            </div>
                                        }
                                    </div>
                                    
                                    {
                                        copyIdioma == "" ? "Para gerar uma copia é necessário escolher um idioma" : <div className="new_block_text" onClick={ ()=>{ DuplicateData() } }>Salvar</div>
                                    }

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
