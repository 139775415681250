import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgLogoTipo, SvgLogoTipo_2, SvgMenuHamburguer, SvgMenuPoint, SvgMenu_About, SvgMenu_Contact, SvgMenu_Dashboard, SvgMenu_Exit, SvgMenu_Home, SvgMenu_Portfolio, SvgMenu_UserDash, SvgMenu_Works, SvgSite, SvgUser } from 'components/SvgFile';

/* popup */
import PopUP_ShowFile       from 'components/PopUp/ShowFile';
import PopUP_EditProfile    from 'components/PopUp/EditProfile';
import PopUP_Confirmation   from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
/* end */

/* page */
import Page_Users from "components/Page/Users";
import Page_Users_Details from 'components/Page/Users/Details';
import Page_Home from 'components/Page/Home';
import Page_About from 'components/Page/About';
import Page_Works from 'components/Page/Works';
import Page_Contact from 'components/Page/Contact';
import Page_Portfolio from 'components/Page/Portfolio';
import Page_Home_Details from 'components/Page/Home/Details';
import Page_About_Details from 'components/Page/About/Details';
import Page_Contact_Details from 'components/Page/Contact/Details';
import Page_Portfolio_Details from 'components/Page/Portfolio/Details';
import Page_Works_Details from 'components/Page/Works/Details';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import Page_ConfigDash from 'components/Page/ConfigDash';
/* end */

export default function Contents(){
    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function ClickPage(value){
        SetListPag('currentPage', value);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "index":
                if(userAccess == 1){
                    return <Page_ConfigDash ClickPage={ ClickPage } />;
                }
                return(
                    <div className="" style={ { textAlign: "center" } }>
                        Selecione uma opção do menu para prosseguir
                    </div>
                );

            case "home":
                return <Page_Home ClickPage={ ClickPage } />;
            case "home_details":
                return <Page_Home_Details ClickPage={ ClickPage } />;

            case "about":
                return <Page_About ClickPage={ ClickPage } />;
            case "about_details":
                return <Page_About_Details ClickPage={ ClickPage } />;

            case "works":
                return <Page_Works ClickPage={ ClickPage } />;
            case "works_details":
                return <Page_Works_Details ClickPage={ ClickPage } />;

            case "contact":
                return <Page_Contact ClickPage={ ClickPage } />;
            case "contact_details":
                return <Page_Contact_Details ClickPage={ ClickPage } />;

            case "portfolio":
                return <Page_Portfolio ClickPage={ ClickPage } />;
            case "portfolio_details":
                return <Page_Portfolio_Details ClickPage={ ClickPage } />;

            case "accessDash":
                return <Page_Users ClickPage={ ClickPage } />;
            case "accessDash_details":
                return <Page_Users_Details ClickPage={ ClickPage } />;

            default:
                return(
                    <div className="" style={ { textAlign: "center" } }>
                        Selecione uma opção do menu para prosseguir
                    </div>
                );
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <a href="https://chromagarden.com/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    {
                                        status == true ?
                                        <SvgLogoTipo_2 color="#ffffff" className="logotipo2" /> :
                                        <SvgLogoTipo color="#ffffff" className="logotipo" />
                                    }
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("index"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_Dashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Dashboard</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "home" || currentPage == "home_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("home"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_Home color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Home</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "about" || currentPage == "about_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("about"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_About color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Quem somos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "works" || currentPage == "works_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("works"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_Works color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Como funciona</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "contact" || currentPage == "contact_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("contact"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_Contact color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Contato</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "portfolio" || currentPage == "portfolio_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("portfolio"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_Portfolio color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Portfólio</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "accessDash" || currentPage == "accessDash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("accessDash"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenu_UserDash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Usuários</div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            switch (key.page) {
                                                case 'home':
                                                        iconMenu = <SvgMenu_Home color="#FFC400" className="icons_dash" />;
                                                    break;
                                                case 'about':
                                                        iconMenu = <SvgMenu_About color="#FFC400" className="icons_dash" />;
                                                    break;
                                                case 'works':
                                                        iconMenu = <SvgMenu_Works color="#FFC400" className="icons_dash" />;
                                                    break;
                                                case 'contact':
                                                        iconMenu = <SvgMenu_Contact color="#FFC400" className="icons_dash" />;
                                                    break;
                                                case 'portfolio':
                                                        iconMenu = <SvgMenu_Portfolio color="#FFC400" className="icons_dash" />;
                                                    break;
                                            }
                                            
                                            let activeMenu = '';
                                            if(key.page == 'home' && currentPage == 'home_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'about' && currentPage == 'about_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'works' && currentPage == 'works_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'contact' && currentPage == 'contact_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'portfolio' && currentPage == 'portfolio_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else {
                                                status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                            }
                                            
                                            return(
                                                <div className={ activeMenu } onClick={ ()=>{ ClickPage(key.page); } } key={ index }>
                                                    <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                        <div className="div_icons">
                                                            { iconMenu }
                                                        </div>
                                                        <div className={ status == true ? "menu_close" : "" }>
                                                            { key.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgMenu_Exit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_ReturnResponse />
            <PopUP_Confirmation />
            <PopUP_ShowFile />
            <PopUP_EditProfile />
            <PopUp_PhotoCrop />
        </div>
    )
}
